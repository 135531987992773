<script>
  import { createEventDispatcher } from "svelte";

  import Comment from "./Comment.svelte";

  export let comment_list;
  export let params;

  let comments;

  const dispatch = createEventDispatcher();

  $: init(comment_list);

  function init(comment_list) {
    comments = [];
    comment_list.forEach((comment) => add_child(comment));
  }

  function find_id(comments, id) {
    for (const comment of comments) {
      if (comment.id === id) return comment;
      const found = find_id(comment.children || [], id);
      if (found) return found;
    }
  }

  const newest = (a, b) => b.id - a.id;

  function add_child(comment) {
    let parent = find_id(comments, comment.parent_id);
    if (parent) {
      parent.children = [...(parent.children || []), comment].sort(newest);
    } else {
      comments = [...comments, comment].sort(newest);
    }
  }
</script>

<ul class="comments comments__root">
  {#each comments as comment (comment.id)}
    <Comment {...comment} {params} on:refresh={() => dispatch("refresh")} />
  {/each}
</ul>
