<script>
  import { createEventDispatcher } from "svelte";
  import { csrfHeader } from "../lib";

  export let params;
  export let reply_to = 0;
  export let autofocus = false;

  const dispatch = createEventDispatcher();
  const ENTER = 13;

  let comment = "";

  const extra_data = {
    name: "",
    email: "",
    url: "",
    followup: "false",
  };

  function login_url() {
    const url = document.location;
    return `${params.login_url}?next=${url.pathname}${url.search}`;
}

  function validate(fields) {
    if (!fields.comment) return false;
    if (fields.honeypot !== "") return false;
    return true;
  }

  async function submit(e) {
    const fields = Object.fromEntries(new FormData(e.target));
    if (!validate(fields)) return;
    let ok = await send(fields);
    if (!ok) {
      console.error("Couldn’t send form data");
      return;
    }
    dispatch("comment-sent", { fields });
    reset();
  }

  async function send(fields) {
    const headers = csrfHeader();
    const body = new URLSearchParams({ ...extra_data, ...fields });
    let response = await fetch(params.send_url, { method: "post", body, headers });
    if (!response || !response.ok) return false;

    const id = (await response.json()).id;
    const like = new URLSearchParams({ comment: `${id}`, flag: "like" });
    await fetch(params.feedback_url, { method: "post", body: like, headers });
    return response.ok;
  }

  function onKeyPress(e) {
    // Submit with Ctrl + Enter
    if (e.ctrlKey && e.keyCode === ENTER) {
      e.target.form.querySelector('button[type="submit"]').click();
    }
  }

  function reset() {
    comment = "";
  }

  function focus(node, autofocus) {
    if (autofocus) node.focus();
  }
</script>

{#if params.is_authenticated}
  <form
    class="comment-form"
    method="post"
    action={params.send_url}
    on:submit|preventDefault={submit}
  >
    <input type="hidden" name="content_type" value={params.default_form.content_type} />
    <input type="hidden" name="object_pk" value={params.default_form.object_pk} />
    <input type="hidden" name="security_hash" value={params.default_form.security_hash} />
    <input type="hidden" name="timestamp" value={params.default_form.timestamp} />
    <input type="hidden" name="reply_to" value={reply_to} />

    <div style="display: none;">
      <input type="text" name="honeypot" value="" />
    </div>

    <textarea
      name="comment"
      id="id_comment"
      placeholder="Your english comment"
      maxlength="3000"
      required
      class="comment-form__textarea"
      use:focus={autofocus}
      on:keypress={onKeyPress}
      bind:value={comment}
    />

    <button type="submit" name="post" class="comment-form__submit">
      Comment
    </button>
  </form>
{:else}
  <p class="comment-form__logout-message">
  <a href="{login_url()}">Log in</a> to comment

  </p>
{/if}
