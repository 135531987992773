<script>
  import { csrfHeader } from "../lib";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import CommentForm from "./CommentForm.svelte";
  import CommentFeedback from "./CommentFeedback.svelte";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import ProjectMarkdown from "./ProjectMarkdown.svelte";

  dayjs.extend(relativeTime);

  export let allow_reply;
  export let comment;
  export let flags;
  export let id;
  export let is_removed;
  export let level;
  export let parent_id;
  export let permalink;
  export let submit_date;
  export let user_avatar;
  export let user_moderator;
  export let is_private;
  export let can_change_is_private;
  export let user_name;

  export let children = [];

  export let params;

  const dispatch = createEventDispatcher();

  let form_visible = false;

  // parse timestamp
  $: date = dayjs(submit_date);
  $: date_formatted = date.format("D MMMM YYYY, H:mm");
  let relative_date;

  async function comment_sent(event) {
    dispatch("refresh");
    form_visible = false;
  }

  let interval;

  onMount(() => {
    relative_date = date.fromNow();
    interval = setInterval(() => (relative_date = date.fromNow()), 5000);
  });

  async function toggle_private(comment_id) {
    if (!params) return;
    const headers = csrfHeader();
    headers.append("Content-Type", "application/json");
    const response = await fetch(params.toggle_is_private_url + comment_id + "/", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ is_private: !is_private }),
    });
    if (response.ok) {
      is_private = !is_private;
    }
  }

  onDestroy(() => clearInterval(interval));
</script>

<li class="comments__thread">
  {#if !is_removed}
    <div
      id={`c${id}`}
      class="comment"
      data-parent={parent_id}
      data-level={level}
    >
      <figure class="comment__avatar">
        {#if user_avatar}
          <img src={user_avatar} alt="avatar" class="comment__avatar-image" />
        {:else}
          <div class="comment__avatar-placeholder" />
        {/if}
      </figure>

      <div class="comment__info">
        {#if user_moderator}
          <span class="user-badge" title="BlenderKit's validators" data-bs-toggle="tooltip">V</span>
        {/if}
        <strong class="comment__username">{user_name}</strong>

        {#if can_change_is_private}
          <small class="comment__badge comment__badge clickable comment__badge comment__badge__{is_private?'private':'public'}" data-bs-toggle="tooltip" title="Comment and it's thread is visible only for it's authors, asset authors and validators."  on:click={toggle_private(id)}>
            {#if is_private}
            private
            {:else}
            public
            {/if}
          </small>
        {/if}

        <time
          datetime={date.format()}
          data-timestamp={date.unix()}
          title={date_formatted}
          class="comment__time"
        >
          <a href={permalink} target="_blank">
            {relative_date}
          </a>
        </time>
      </div>

      <div class="comment__content">
        <ProjectMarkdown source={comment} />
      </div>

      <div class="comment__actions">
        <CommentFeedback
          {id}
          feedbacks={flags}
          url={params.feedback_url}
          current_user={params.current_user}
          is_authenticated={params.is_authenticated}
        />

        {#if params.is_authenticated && allow_reply}
          <button
            class="comment__reply"
            on:click={() => (form_visible = !form_visible)}
          >
            Reply
          </button>
        {/if}

        {#if false}
          <button class="comment__more-actions">
            <span class="fas fa-ellipsis-h" />
          </button>
        {/if}
      </div>

      {#if allow_reply && form_visible}
        <div class="comment__reply-form">
          <CommentForm
            {params}
            reply_to={id}
            autofocus
            on:comment-sent={comment_sent}
          />
        </div>
      {/if}
    </div>
  {/if}

  {#if children}
    <ul class="comments">
      {#each children as child (child.id)}
        <svelte:self
          {...child}
          {params}
          on:refresh={() => dispatch("refresh")}
        />
      {/each}
    </ul>
  {/if}
</li>
