<script>
  import dayjs from "dayjs";
  import CommentCount from "./CommentCount.svelte";
  import CommentForm from "./CommentForm.svelte";
  import CommentTree from "./CommentTree.svelte";

  export let asset_id;

  let params;
  let overrides = {
    allow_comments: true,
    allow_feedback: true,
    allow_flagging: true,
    show_feedback: true,
  };
  let comment_count;
  let comment_list = [];
  let intervals = {};

  $: !asset_id && cleanup();
  $: comment_count && load_comments(); // fetch when the count changes

  async function init(asset_id) {
    params = await fetch_params(asset_id);
    comment_count = params.comment_count;
    if (params.polling_interval) {
      intervals.load_count = setInterval(load_count, params.polling_interval);
    }
  }

  async function fetch_params(asset_id) {
    const response = await fetch(`/asset-comment/${asset_id}/`);
    if (response.ok) {
      return Object.assign(await response.json(), overrides);
    }
  }

  function timestamp_query() {
    const timestamp = dayjs().subtract(10, "minutes").unix();
    return new URLSearchParams({ _: timestamp.toString() });
  }

  async function load_count() {
    if (document.hidden) return; // don't check on inactive tabs
    if (!params) return;
    var response;
    try {
        response = await fetch(`${params.count_url}?${timestamp_query()}`);
    } catch (TypeError) {
        console.log("Error loading comment count, network down");
        return;
    }
    comment_count = (await response.json()).count;
  }

  async function load_comments() {
    if (!params) return;
    const response = await fetch(params.list_url);
    comment_list = await response.json();
  }

  async function toggle_private(comment_id) {
    if (!params) return;
    const response = await fetch(params.toggle_is_private_url + comment_id, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ is_private: !is_private }),
    });
    if (response.ok) {
      is_private = !is_private;
    }
  }

  function cleanup() {
    clearInterval(intervals.load_count);
    comment_list = [];
    comment_count = null;
  }
</script>

{#if asset_id}
  <aside class="asset-comments">
    {#await init(asset_id)}
      <p class="asset-comments--loading">Loading comment…</p>
    {:then}
      <CommentCount count={comment_count} />

      <CommentForm {params} on:comment-sent={() => (comment_count += 1)} />

      <CommentTree {comment_list} {params} on:refresh={load_comments} />
    {:catch}
      <p class="asset-comments--error">Error loading comments</p>
    {/await}
  </aside>
{/if}
