import Comments from "./Comments.svelte";

let djangoCommentsXtd;

const assetModal = document.getElementById("assetModalDetail");

if (assetModal) {
    djangoCommentsXtd = new Comments({
        target: document.getElementById("comments"),
        props: { asset_id: assetModal.dataset.asset || undefined },
    });

    assetModal.addEventListener("shown.bs.modal", () => {
        djangoCommentsXtd.$set({ asset_id: assetModal.dataset.asset });
    });
    assetModal.addEventListener("hidden.bs.modal", () => {
        djangoCommentsXtd.$set({ asset_id: undefined });
    });

    document.addEventListener("activeAssetReloaded", () => {
        djangoCommentsXtd.$set({ asset_id: undefined });
        // Need to wait for the next tick, otherwise the Svelte component
        // does not update the asset_id to undefined and the component is not cleaned up
        // (causes a flood of requests to the server)
        setTimeout(() => {
            djangoCommentsXtd.$set({ asset_id: window.gallery.activeAsset });
        }, 0);
    });
}

window.djangoCommentsXtd = djangoCommentsXtd;

export default djangoCommentsXtd;
