<script>
    import {marked} from 'marked';
    import sanitizeHtml from 'sanitize-html';

    export let source;
    let html = '';

    const renderer = new marked.Renderer();
    renderer.link = function (href, title, text) {
        return title
            ? `<a href="${href}" title="${title}" target="_blank" rel="nofollow noopener noreferrer">${text}</a>`
            : `<a href="${href}" target="_blank" rel="nofollow noopener noreferrer">${text}</a>`;
    };

    // can be used to remove some tags during sanitization (after markdown conversion)
    const allowedTags = [...sanitizeHtml.defaults.allowedTags, "img", "details", "summary"];

    // automatically update the converted HTML when 'source' changes
    $: try {
        const rendered = marked(source, {renderer, breaks: true, headerIds: false});
        html = sanitizeHtml(rendered, {
            allowedTags: allowedTags,
            allowedAttributes: {
                ...sanitizeHtml.defaults.allowedAttributes,
                'a': ['href', 'name', 'target', 'title', 'rel'],
                'img': ['src', 'alt', 'title', 'width', 'height']
            },
            exclusiveFilter: function (frame) {
                // remove images with missing src attribute or non-HTTP(S) src attribute
                return frame.tag === 'img' && (!frame.attribs.src || !frame.attribs.src.startsWith('http'));
            },
            transformTags: {
                'a': (tagName, attribs) => {
                    return {
                        tagName: tagName,
                        attribs: {
                            ...attribs,
                            target: '_blank',
                            rel: 'nofollow noopener noreferrer'
                        }
                    }
                },
                // tranform h tags to be one level smaller (h1 is reserved for page title)
                'h1': 'h2',
                'h2': 'h3',
                'h3': 'h4',
                'h4': 'h5',
                'h5': 'h6',
                'h6': 'h6' // keep h6 as h6
            }
        });
    } catch (err) {
        console.error('Failed to parse markdown', err);
        html = `<p style="color: red;">Sorry, there was a problem rendering the content.</p>`;
    }
</script>

<div class="markdown">
    {@html html}
</div>
