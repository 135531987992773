<script>
  import { csrfHeader } from "../lib";

  export let feedbacks;
  export let url;
  export let id;
  export let current_user;
  export let is_authenticated;

  const ACTION = { like: "like", dislike: "dislike" };
  const user_id = parseInt(current_user.split(":")[0]);
  const initial = feedbacks.slice();

  $: likes = feedbacks.filter((f) => f.flag === ACTION.like);
  $: dislikes = feedbacks.filter((f) => f.flag === ACTION.dislike);
  $: liked = likes.filter((f) => f.id === user_id).length > 0;
  $: disliked = dislikes.filter((f) => f.id === user_id).length > 0;

  $: count = likes.length - dislikes.length;

  function toggle(action) {
    if (
      (action == ACTION.like && liked) ||
      (action == ACTION.dislike && disliked)
    ) {
      update_feedbacks();
    } else update_feedbacks(action);
    return send(action);
  }

  function update_feedbacks(action) {
    const others = feedbacks.filter((f) => f.id != user_id);
    feedbacks = action
      ? [...others, { flag: action, user: "", id: user_id }]
      : others;
  }

  async function send(action) {
    if (!is_authenticated) return;
    const body = new URLSearchParams({ comment: `${id}`, flag: action });
    const headers = csrfHeader();
    let response = await fetch(url, { method: "post", body, headers });
    if (!response || !response.ok) reset();
    return response;
  }

  function reset() {
    feedbacks = initial;
  }
</script>

<button
  class="comment__feedback like"
  class:comment__feedback--active={liked}
  on:click={(e) => toggle(ACTION.like)}
>
  <span class="fas fa-arrow-up" />
</button>

<span class="comment__feedback-count">{count}</span>

<button
  class="comment__feedback dislike"
  class:comment__feedback--active={disliked}
  on:click={(e) => toggle(ACTION.dislike)}
>
  <span class="fas fa-arrow-down" />
</button>
